import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

// Connects to data-controller="datepicker"
export default class extends Controller {
  open(e) {
    e.preventDefault();
    flatpickr(e.target, {
      disableMobile: 'true',
    });
  }

  openRange(e) {
    e.preventDefault();
    flatpickr(e.target, {
      disableMobile: 'true',
      mode: 'range',
      minDate: 'today',
      disable: JSON.parse(this.element.dataset.disabledDateRanges),
      dateFormat: "Y-m-d",
      onValueUpdate: (selectedDates, dateStr, instance) => {
        if (selectedDates.length === 2) {
          document.getElementById(this.element.dataset.checkinId).value = dateStr.split(' to ')[0];
          document.getElementById(this.element.dataset.checkoutId).value = dateStr.split(' to ')[1];
        }
      },
    });
  }
}
